.logoutPopup {
  position: absolute;
  top: 0px;
  left: -100px;
  width: calc(100vw + 100px) ;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout_content {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  box-shadow: 6px 6px 5px black;
  border-radius: 20px;
  flex-direction: column;
  position: relative;
}
.logout_content>h1 {
  margin: 0;
  margin-bottom: 0.67em;
}
.logout_button {
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.button_container >h5{
  color: grey;
  font-weight: 500;
}
.talkbubble{
  position: absolute;
  top: 90%;
  left: 100px;
  white-space: nowrap;
  letter-spacing:0.5px;
  font-size: 14px;
  text-transform: capitalize;
}
#talkbubble{
  padding: 6px 12px;
  border-radius: 1rem;
}

/* .logout_button .simple_Logout_button {
  border: none;
  color: var(--white);
  background: var(--main);
  width: 200px;
  padding: 10px 0px;
  margin: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
} */
.clear_Logout_button, .simple_Logout_button{
  border: none;
  color: var(--white);
  background: var(--main);
  width: 200px;
  padding: 10px 0px;
  margin: 10px 20px;
  border-radius: 1.5rem;
  cursor: pointer;
  position: relative;
  letter-spacing: 1px;
  font-weight: 500;
  white-space: nowrap;
  box-shadow: 3px 4px 3px black;
  transition: all 0.3s ease-in-out;
}
.clear_Logout_button:hover, .simple_Logout_button:hover{
  transform: scale(1.05);
}

.button_Close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  color: black;
  border-radius: 20px;
  cursor: pointer;
}
.button_Close:hover{
    background: rgba(0, 0, 0, 0.5);
    color: red;
}
