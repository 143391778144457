.table-container-user {
  background: var(--white);
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 5px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left;
}

.user-table tr {
  text-align: left;
  border-bottom: 1px solid #d0cece;
  color: #000;
}

.user-table thead {
  background: #deffe1;
  padding: 10px;
}

.user-table thead th {
  padding: 10px;
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  border-right: 1px solid grey;
}

.user-table tbody {
  text-align: center;
}

.user-table tbody td {
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
}

.user-table tbody .fieldEditButton {
  border: none;
  text-decoration: none;
  padding: 10px;
  margin: auto 10px;
  font-weight: bold;
  background: var(--main);
  border-radius: 10px;
  color: #f2f2f2;
  cursor: pointer;
}

.user-table tbody .fieldEditButton:hover {
  background: #44cd4b85;
}

.user-table thead tr th.user-mobile {
  width: 180px;
}

.user-table thead tr th.user-name {
  width: 220px;
}

.user-table thead tr th.user-visits {
  width: 80px;
}

.user-table thead tr th.user-balance {
  width: 120px;
}

.user-table thead tr th.user-address {
  width: 300px;
}

.filter-input-row, .filter-input-row>div {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10px;
  padding: 10px;
}

.filter-input-row>div>h4 {
  white-space: nowrap;
}

.filter-input-row>div {
  padding: 0;
}

.filter-input-row>div>input {
  max-width: 400px;
  margin: auto 10px;
}

#balanceIco {
  width: 30px;
  height: 30px;
  color: black;
  margin-right: 5px;
  transition: all .5s ease;
}

#toggle-customer-balances>span {
  display: inline-block;
  position: relative;
  top: -10px;
  transition: all .5s ease;
}

#toggle-customer-balances {
  background: transparent;
  border: none;
  padding: 10px 25px;
  white-space: nowrap;
  transition: all .2s;
}

#toggle-customer-balances:hover, #toggle-customer-balances.active {
  border-radius: 3px;
  background: #44cd4b2e;
  color: #2fa234;
}

#toggle-customer-balances:hover #balanceIco, #toggle-customer-balances.active #balanceIco {
  color: #00b31a;
}