/* Reference Scheme PopUp Styles */
.popupContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000007a;
    z-index: 1;
}

.popupBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
    min-width: 500px;
    max-height: 80%;
    background: white;
    border-radius: 16px;
}

.popupBody>h2 {
    width: 100%;
    text-align: center;
    padding: 20px;
    background: black;
    color: white;
    border-radius: 16px 16px 0 0;
}

.popupBody>div {
    margin: 20px;
    padding: 20px;
    border: 2px solid #5f5f5f;
    width: calc(100% - 40px);
    border-radius: inherit;
}

.formsContainer {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #00000007;
    padding: 20px;
    border-radius: inherit;
    overflow-y: auto;
    max-height: calc(80vh - 260px);
    gap: 5px;
}

.formsContainer>form {
    display: flex;
}

.formsContainer>form>input[type='text'] {
    padding: 8px;
    border: none;
    background: #00000015;
    border-radius: 5px;
    width: 18rem;
}

.formsContainer>form>input[type='checkbox'] {
    margin: auto 10px;
    width: 30px;
    height: 30px;
}

.formsContainer>form>button {
    padding: 6px 20px;
    border: none;
    background: var(--main);
    color: white;
    border-radius: 5px;
    font-size: 18px;
}

svg.close {
    color: white;
    position: absolute;
    top: 24px;
    right: 15px;
    height: 24px;
    width: fit-content;
}

.btnContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;
}

.submitBtn {
    background: var(--main);
    border: none;
    outline: none;
    padding: 0 20px;
    border-radius: 5px;
    width: 80%;
    color: white;
}

.submitBtn:disabled {
    background-color: grey;
    color: white;
    cursor: not-allowed;
}

.noSchemeMessage {
    font-size: larger;
    font-weight: 600;
    text-align: center;
    line-height: 30px;
    width: calc(18rem + 75px);
    padding: 6px;
}

/* Reference Scheme Page Styles */
.scheme_form_popup_container {
    position: absolute;
    max-height: 80vh;
    overflow: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 16px;
}

.formHead {
    padding: 20px;
    background: black;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1;
}

.formHead>h2 {
    text-align: center;
}

.scheme_form_container {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    max-height: calc(80vh - 70px);
    padding: 30px 20px;
}

.scheme_form {
    margin: auto;
    display: flex;
    flex-direction: column;
    background: white;
}

.scheme_form>h2 {
    margin: 20px 0;
}

.inputGroupDiv {
    background: #0000000a;
    padding: 20px 10px;
    border-radius: 12px;
}

.inputGroupDiv>label>div {
    display: flex;
}

.inputGroupDiv>label {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    gap: 20px;
}

.inputGroupDiv input {
    padding: 10px;
}

.datePicker {
    width: 20rem;
    margin-left: auto;
}

input.date_selector {
    width: 100%;
}

.payModesDiv {
    flex-direction: column;
    width: 20rem;
}

.payModesDiv>label {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.inputs {
    background: #e2e2e2ab;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 12px;
    width: 20rem;
}

.inputCheckbox {
    width: 20px;
    height: 20px;
    margin-right: calc(12rem - 24px);
}

.requiredInput {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.inputs_sided {
    border-radius: 8px 0 0 8px;
    width: 14rem;
}

.commissionManageBtn {
    margin: 0;
    background: #e9e9e9;
    color: #858585;
    border-radius: 5px;
    border: none;
    padding: 12px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.saveBtn {
    margin: 30px auto 0;
    border: none;
    padding: 12px 60px;
    border-radius: 50px;
    color: white;
    background: var(--main);
}

.messagePopupContainer {
    width: 100%;
    gap: 1rem;
    margin-top: 20px;
    border: none;
    padding: 0;
}

.messagePopupContainer>div>label {
    width: 100%;
}

.messagePopupContainer>div>label>textarea {
    width: 100%;
    background: #e9e9e9;
    padding: 10px;
    border: none;
    height: 100px;
}

.commissionManagePopupContainer {
    width: 100%;
    height: 100%;
    background: #00000073;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

div .popupSpinner {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: #0000007a;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: progress;
}

div .popupSpinner svg {
    width: 5rem;
    height: fit-content;
    pointer-events: none;
}