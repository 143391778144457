.overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  background-color: var(--white);
  border-radius: 1rem;
  position: relative;
  height: 100%;
}

.content {
  /* overflow: scroll; */
  padding: 0;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 12px;
  padding: 0 0.5rem;
  background-color: black;
  color: white;
  border: 0;
  font-size: 2rem;
  border-radius: 100vh;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.closeButton:hover {
  background-color: var(--mainColor);
}