.header_toggle_btn {
  position: relative;
  padding: 1px 10px;
  color: #fff;
}

.header_toggle_btn .icon_btn {
  font-size: 30px;
  cursor: pointer;
}
.header_toggle_btn .toggle_dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0;
  width: fit-content;
  max-height: 350px;
  background-color: white;
  box-shadow: 2px 5px 5px black;
  padding: 20px;
  padding-right: 0px;
  border-top: 1px solid grey;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 1.5rem;
}
.header_toggle_btn .toggle_dropdown .toggle_dropdown_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  font-weight: 500;
  white-space: nowrap;
  letter-spacing: 0.5px;
  color: #000;
}
.toggle_dropdown_notification_container {
  overflow: auto;
}
.toggle_dropdown_notification_container::before {
  content: "";
  position: absolute;
  /* z-index: 9999; */
  top: 30px;
  left: 20px;
  transform: translateX(-50%);
  border: 10px solid;
  border-color: transparent transparent var(--main) transparent;
}
.noScroll::-webkit-scrollbar{
  width: 0 !important;
  height: 0 !important;
}