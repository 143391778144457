body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --mainColor: var(--main);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#loading-screen {
  padding: 7px;
}

#loading-screen svg {
  height: 30px;
}

#kitchen-ico {
  position: fixed;
  bottom: 0;
  right: 430px;

  border: 2px solid #000;
  padding: 10px 12px;
  padding-bottom: 5px;
  border-radius: 2rem 2rem 0 0;
  cursor: pointer;
  color: #000;
}
#kitchen-ico:hover {
  border: 2px solid var(--main);

  color: var(--main);
}

#kitchen-ico > img {
  width: 20px;
  height: 20px;
}

.gridview {
  width: 1.5rem;
}

.gridviewIco {
  height: 2.5rem;
  outline: none;
  cursor: grab;
  color: #0000007a;
  width: 75%;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#suggestions-div,
#suggestions-payment-div {
  display: flex;
  justify-content: flex-start;
}

#suggestions-div > div {
  max-height: 80%;
}

#suggestions-div > div,
#suggestions-payment-div > div {
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 1000;
  overflow: auto;
  border: 1px solid rgba(128, 128, 128, 0.609);
  border-radius: 5px;
}

#suggestions-payment-div > div {
  height: fit-content;
}

.contact-suggestion {
  background: white;
  width: 192px;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.294);
  transition: all 0.3s;
}

.contact-suggestion:hover {
  background-color: #e9ecef;
}

.contact-suggestion > .bold {
  font-size: 16px;
  font-weight: 600;
  padding: 2px 0;
}

.contact-suggestion > .light {
  font-size: 14px;
  font-weight: 400;
}

#login-container {
  width: 100vw;
  height: 100vh;
  /* background: var(--main); */

  display: flex;
  align-items: center;
  justify-content: space-between;
}
#login-container .heading {
  background: var(--main);
  width: 40vw;
  height: 100vh;
  color: #fff;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
#login-container .form-comtainer {
  width: 60vw;
  height: 100vh;
  background-color: var(--gray);
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#login-container h1 {
  font-size: 1.5rem;
  margin: 0;
  letter-spacing: 3px;
  text-align: center;

  width: 300px;

  padding: 20px;
}

#login-container .form-comtainer > .form {
  width: 300px;
  height: 300px;

  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-direction: column;

  background-color: #fff;
  border-radius: 1rem;
  padding: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 90%;
  gap: 8px;
  background: transparent;
}

#login-container .input-container input {
  background: var(--gray);
  /* border: none;
  border-bottom: 2px solid #000; */
  color: #000;
  font-size: 18px;
}

#login-container .input-container input:-internal-autofill-selected {
  background: black;
  border: none;
  border-bottom: 2px solid #fff;
  color: #fff;
  font-size: 18px;
}

.input-container > label {
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: 1.5px;
  font-weight: 600;
}

.submit-btn {
  padding: 10px 3rem;
  background: var(--main);
  border: 2px solid var(--main);
  border-radius: 1rem;
  width: fit-content;
  min-width: 10rem;
  font-weight: bold;
  font-size: 18px;
  color: white;
  letter-spacing: 1.5px;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 100%;
}

.submit-btn:hover {
  border: 2px solid white;
  background: var(--white);
  color: var(--main);
  scale: 1.05;
}

.submit-btn:hover svg {
  color: var(--main);
}

.input-container > input {
  padding: 10px;
  background: black;
  color: white;
  font-size: 20px;
  max-width: 300px;
  letter-spacing: 1px;
  outline: none;
  border: none;
  border-bottom: 2px solid white;
}

.input-container > input:focus {
  outline: none;
}

.App {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  overflow: hidden;
}

.left-panel {
  display: flex;
  flex-direction: column;
  width: 70px;
  height: 100vh;
  background-color: white;
  z-index: 10;
}

.left-panel img {
  width: 100%;
  padding: 5px;
  max-width: 150px;
}

div.unsync-count-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
}

.unsync-count-container:hover {
  background: #87878742;
  cursor: pointer;
}

.unsync-count-container > div {
  font-size: 1.6rem;
  font-weight: bold;
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding: 10px 0;
  background: #fff;
}

.nav > div {
  width: calc(100% + 10px);
  height: calc(100vh - 110px);
  overflow-y: scroll;
  overflow-x: clip;
  position: relative;
  right: -5px;
}

.nav_title {
  font-size: 12px;
  font-weight: 500;
  white-space: pre-wrap;
  width: 100%;
  text-align: center;
}

.nav_title.page2 {
  font-size: 14px;
  text-align: start;
  padding: 0 5px;
}

.nav_link_container {
  width: 70px;
  color: inherit;
  text-decoration: none;
}

.nav-link:hover {
  background-color: #fff;
  border-left: 5px solid var(--main);
}

.nav-link {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 20px 0;
  border-left: 5px solid transparent;
}

.nav-link.page5 {
  padding: 8px 4px;
}

.nav-link.page5 .nav_title {
  white-space: break-spaces;
  text-align: left;
}

.nav-link.active {
  background-color: #fff;
  border-left: 5px solid var(--main);
}

.nav-link > i {
  font-size: 32px;
}

.nav-link > p {
  display: flex;
  align-items: center;
  width: inherit;
}

.nav-link > .menu {
  overflow: hidden;
  position: absolute;
  left: 85%;
  top: -10px;
  z-index: 1;
  width: 200px;
  background-color: white;
  border-radius: 10px;
  transition: 0.3s all ease;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.nav-link > .menu > .item > a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 10px;
  color: black;
}
.nav-link > .menu > .item > a:hover {
  color: var(--main);
}

.nav-link > .menu > .item {
  border-bottom: 1px solid grey;
}

.nav-link > .menu > .item:hover {
  color: var(--main) !important;
  border-bottom: 1px solid var(--main);
}
.nav-link > .menu > .item.Link:hover {
  color: var(--main) !important;
}

.right-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--gray);
}

.refreshButton {
  position: absolute;
  cursor: pointer;
  background: var(--main);
  color: var(--white);
  left: 90%;
  top: 60px;
  padding: 10px;
  border: 1px solid var(--main);
  border-radius: 10px;
}

.refreshButton:hover {
  color: var(--main);
  background: var(--white);
}

.popupRefresh {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.saveButton {
  cursor: pointer;
  background: var(--main);
  z-index: 999;
  color: var(--white);
  padding: 10px;
  border: 1px solid var(--main);
  border-radius: 10px;
}

.saveButton:hover {
  color: var(--main);
  background: var(--white);
}

.messageRefresh {
  padding: 20px 0px;
  width: 100%;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: var(--main);
  z-index: 999999999;
  align-items: center;
  justify-content: space-between;
}

.header > h2 {
  text-align: right;
  color: #fff;
  width: 50vw !important;
}

.header_right {
  height: 100%;
  background-color: var(--main);
  display: flex;
}

.header_right_link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  margin-left: 5px;
  background-color: #fff;
  border: 1px solid grey;
  font-weight: 500;
  cursor: pointer;
}

.horizontal-tabs {
  width: calc(100% - 470px);
  margin-left: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.horizontal-tabs > .tabs {
  flex: 1;
  margin: 10px;
  height: 30px;
  /* background-color: black; */
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}

.horizontal-tabs > .button_tabs {
  margin: 10px;
  width: 100px;
  height: 40px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

#keyboard-ico {
  border: 2px solid #000;
  height: 30px;
  width: 50px;
  border-radius: 5px;
  color: #000000e7;
}
#keyboard-ico:hover {
  border: 2px solid var(--main);

  color: var(--main);
}

#keyboard-ico.page2 {
  position: absolute;
  top: 50px;
  right: 415px;
}

#keys-shortcuts-container {
  z-index: 1000;
  position: absolute;
  right: 420px;
  top: 110px;
  background: white;
  overflow: hidden;
  border-radius: 10px;
  padding-bottom: 10px;
  outline: none;
  border: none;
  border: 1px solid rgba(128, 128, 128, 0.205);
}

#keys-shortcuts-container > h4 {
  padding: 10px 5px;
  background: var(--main);
  color: white;
}

#keys-shortcuts-container > h4 > #keyboard-ico-inline {
  margin: -6px 2px;
}

#keys-shortcuts-container > .shortcut-row {
  padding: 10px 5px;
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: space-between;
}

.shortcut-row > .arrow {
  height: 20px;
  width: 30px;
  padding: 0;
  margin: -5px;
}

.nav > .tabs {
  flex: 1;
  margin: 1px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.nav > .tabs.active {
  background-color: var(--main);
}

.horizontal-tabs > .tabs.active {
  border-bottom: 5px solid var(--main);
  font-weight: bold;
}

.vertical-tabs {
  width: 40px;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical-tabs.touchTop {
  margin-top: -50px;
}

.vertical-tabs.orders-page {
  margin-top: 0px;
}

.vertical-tabs > .tabs {
  width: 8px;
  flex: 1;
  border: 2px solid #000;
  color: #000;
  margin: 10px;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-wrap: break-word;
  font-weight: 800;
  cursor: pointer;
  align-items: center;
}

.vertical-tabs > .tabs.active {
  color: var(--main);
  border-color: var(--main);
}

.searchBar {
  display: flex;
  width: 50%;
  position: fixed;
  bottom: 0px;
}

.searchBar > input {
  flex: 1;
  padding: 10px;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  border: 1px solid var(--main);
  outline: none;
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  overflow-y: auto;
  /* overflow-x: hidden; */
  padding-bottom: 40px;
  height: calc(100vh - 120px);
}

#req-decline-warning-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.329);
}

#req-decline-warning-popup > div > p {
  text-align: center;
  margin: 20px 10px;
  font-size: 18px;
  white-space: nowrap;
}

#req-decline-warning-popup > div > .btns > * {
  font-size: 16px;
  padding: 10px;
  min-width: 80px;
}

#req-decline-warning-popup > div {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background: white;
  border-radius: 25px;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.categoryDiv {
  margin-left: 20px;
}

.sectionDiv > h1,
.categoryDiv > h1 {
  margin: 5px;
  font-weight: 500;
  font-size: 1.7rem;
}

.sectionDiv,
.categoryDiv {
  padding-bottom: 10px;
}

.context-menu {
  display: none;
  outline: none;
  z-index: 100;
  background: white;
  border: 1px solid #808080f7;
  border-radius: 5px;
  flex-flow: column nowrap;
  position: absolute;
}

.context-menu > * {
  padding: 15px 20px;
  border: none;
  background: white;
  transition: all 0.2s ease;
  font-size: 18px;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  outline: none;
}

.context-menu > button:hover,
.context-dropdown > *:hover {
  background-color: var(--main);
  color: white;
}

.context-dropdown-trigger {
  display: flex;
  justify-content: space-between;
}

.context-dropdown {
  border-radius: 5px;
  position: absolute;
  margin-left: 100%;
  padding: 0;
  height: 0;
  overflow: auto;
  transition: all 0.6s ease-in;
  border: 1px solid transparent;
}

.context-dropdown > * {
  padding: 8px 10px;
  transition: all 0.2s ease;
}

.context-dropdown.active {
  height: fit-content;
  border: 1px solid #80808063;
}

div#seats-list {
  max-height: 60vh;
  outline: none;
}

.card-focus {
  border: none;
  outline: 3px solid transparent;
  min-width: fit-content;
  background-color: transparent;
}

.card-focus.rounded {
  border-radius: 25px;
  cursor: pointer;
}

.card {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 120px;
  width: 120px;
  min-width: 120px;
  padding: 10px 20px;
  position: relative;
  transition: 0.3s all ease;
}

.card > .seatTimer {
  color: red;
  display: flex;
  width: -webkit-fill-available;
  flex-wrap: wrap;
  justify-content: center;
}

.card > .seatTimer > div {
  padding: 0 10px;
}

.card-focus:focus > .card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.8);
}

.card.rounded {
  border-radius: 25px;
}

.card > .title1 {
  font-size: 18px;
  font-weight: 500;
}

.card > .title2 {
  font-size: 18px;
  font-weight: 500;
  height: 25px;
  letter-spacing: 0.5px;
}

.card > .caption {
  color: grey;
  height: 30px;
}

.card > .horizontal-line {
  width: 100%;
  height: 5px;
  background-color: var(--main);
  border-radius: 0px 0px 5px 5px;
}

.card > .horizontal-line.red {
  background-color: red;
}

.card > .price {
  background: transparent;
  color: var(--main);
  font-size: 20px;
  font-weight: 500;
}

.card > .price.red {
  color: red;
  background-color: transparent;
}
.card > .price.green {
  color: green;
  background-color: transparent;
}

.card-focus.highlighted-card {
  outline: 3px solid var(--main);
  opacity: 0.8;
}

.order-request-prompt {
  position: absolute;
  bottom: 25px;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  background: white;
  color: white;
  margin: 0;
  white-space: nowrap;
}

.order-request-prompt > .btns {
  display: flex;
  justify-content: center;
}

.btns > * {
  font-size: small;
  border: none;
  outline: none;
  padding: 6px;
  margin: 2px 3px;
  border-radius: 5px;
  color: white;
}

.btns > #accept {
  background-color: var(--main);
}

.btns > #reject {
  background-color: red;
}

.btns > #complete {
  background-color: blue;
}

.info {
  width: 400px;
  height: calc(100vh - 50px);
  background-color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 10px 10px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
}

tr.order-brand-name {
  font-size: 18px;
  font-weight: 600;
  color: var(--main);
  white-space: nowrap;
  display: block;
  margin: 10px 0;
  width: 100% !important;
}

.info.touchTop {
  position: relative;
  top: -50px;
  height: calc(100vh - 40px);
}

.info-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.inputs {
  display: flex;
  gap: 5px;
}

.inputs input {
  width: 80%;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 3px;
}

#dropdown-toggler {
  display: flex;
  background: #e9e9e9;
  width: 90px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
#dropdown-toggler:hover {
  color: var(--main);
}

#dropdown-toggler > svg {
  transform: rotate(360deg);
  transition: transform 0.2s ease-in;
}

#dropdown-toggler.active > svg {
  transform: rotate(180deg);
}

#dropdown-toggler:hover {
  background: #c1c1c18c;
}

#customer-details-dropdown {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 100px;

  padding: 12px 10px;
  border-radius: 10px;
  font-weight: 500;
  background: var(--white);
  box-shadow: 5px 5px 50px #00000036, -5px -5px 40px #00000012;
  border: none;
  text-align: inherit;
  outline: none;
}

#customer-details-dropdown.page1 {
  right: 0;
  top: 50px;
}

#customer-details-dropdown > #visits {
  color: grey;
}

#customer-details-dropdown > label {
  display: block;
}

#customer-details-dropdown > *,
#customer-details-dropdown > div > textarea {
  padding: 10px 0;
  border-radius: 6px;
  outline: none;
  border: none;
}

#customer-details-dropdown > div {
  padding: 0;
}

#customer-details-dropdown > div > textarea {
  padding: 10px 8px;
  background-color: #d3d3d37a;
  min-width: 200px;
  min-height: 60px;
  height: auto;
  max-width: 375px;
  max-height: 50vh;
}

#customer-details-dropdown #order_remarks {
  border: none;
  outline: none;
  padding: 10px 5px;
  background-color: #d3d3d37a;
  border-radius: 6px;
  width: 100%;
  font-size: 15px;
}

#customer_address + .resize {
  height: 20px;
  position: relative;
  margin: 0 0 0 -24px;
  transform: rotate(270deg);
  pointer-events: none;
  color: #8c8c8c;
}

ul#addresses-list {
  background: white;
  color: black;
  border: 1px solid #00000026;

  position: absolute;
  right: 10px;
  padding: 0 !important;
  border-radius: 10px;
  overflow: hidden;
  z-index: 10000;
}

ul#addresses-list > * {
  list-style: none;
  padding: 12px 10px;
  white-space: nowrap;
  font-weight: 400;
}

ul#addresses-list > *:hover {
  background-color: #fff;
}

.name {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0px;
}

.name h3 {
  flex: 1;
  color: var(--main);
}

.name .edit {
  cursor: pointer;
  color: #000;
  border: none;
  outline: none;
  border: 2px solid #000;
  padding: 8px 30px;
  border-radius: 5px;
}
.name .edit:hover {
  color: var(--main);

  border: 2px solid var(--main);
}
.description {
  width: 100%;
  border-collapse: collapse;
}

.description-container {
  height: 100%;
  overflow: scroll;
  border-collapse: collapse;
}

.description tr > th {
  width: 20%;
  padding: 5px 0;
  text-align: left;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.description tr > th:nth-child(1) {
  width: 35%;
}

.description tr > th:nth-child(2) {
  text-align: center;
  width: 30%;
}

.description tr > th:nth-child(4) {
  width: 10%;
}

.description tr > td:nth-child(4) {
  display: flex;
}

.description tr > td {
  text-align: left;
  padding: 5px 0;
}

.description tr > td:nth-child(2) {
  text-align: center;
}

.description tr > td > .quantityEditable {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.description tr > td > .quantityEditable > input {
  width: 50px;
  border: 1px solid var(--main);
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.description tr > td > .quantityEditable > i {
  color: green;
}

.description tr {
  transition: 0.3s all ease;
}

.description tr:hover {
  background-color: rgb(226, 226, 226);
}

.info-actions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.info-actions > button.col-1 {
  grid-column: auto/span 1;
}

.info-actions > button.col-2,
.info-actions > .button-group.col-2 {
  grid-column: auto/span 2;
}

.info-actions > button.col-4 {
  grid-column: auto/span 4;
}

.info-actions > .button-group {
  color: #000;
  border-radius: 5px;
  border: none;
  position: relative;
  display: flex;
}

.info-actions > .button-group > button {
  border: none;
  outline: none;
  background-color: transparent;
  color: #000;
  cursor: pointer;
  padding: 10px;
  border: 2px solid black;
  border-radius: 5px;
}
.info-actions > .button-group > button:hover {
  color: var(--main);

  border: 2px solid var(--main);
}
.info-actions > .button-group > button {
  border: none;
  outline: none;
  background-color: transparent;
  color: #000;
  cursor: pointer;
  padding: 10px;
  border: 2px solid black;
  border-radius: 5px;
}

.info-actions > .button-group > button:first-child {
  flex: 1;
}

.info-actions > .button-group > button:last-child {
  border-left: 1px solid #000;
  padding: 5px 10px;
}

.info-actions > .button-group > .menu {
  overflow: hidden;
  position: absolute;
  right: 0px;
  bottom: 0%;
  min-width: 100%;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all ease;
  z-index: 1;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}

.info-actions > .button-group > .menu.KOT {
  background: transparent;
  box-shadow: none;
  row-gap: 5px;
  right: -18px;
  white-space: nowrap;
  margin-right: 20px;
}

.info-actions > .button-group > .menu.KOT > button {
  padding: 10px;
  border: none;
  outline: none;
  background: transparent;
  color: var(--black);
  border: 2px solid var(--black);
  border-radius: 5px;
}
.info-actions > .button-group > .menu.KOT > button:hover {
  color: var(--main);
  border-color: var(--main);
}
.info-actions > .button-group > .menu.active {
  opacity: 1;
  bottom: 110%;
  pointer-events: unset;
}

.info-actions > button,
.info-actions > .pay-btns > button {
  padding: 10px;
  color: var(--main);

  border-radius: 5px;
  border: none;
  border: 2px solid var(--main);
  outline: none;
  cursor: pointer;
  position: relative;
}

.info-actions > .pay-btns {
  grid-column: auto/span 4;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  position: relative;
}

.info-actions > button:last-child {
  background-color: var(--main);
  padding: 10px;
}

.info-actions > .menu {
  overflow: hidden;
  position: absolute;
  right: 0px;
  bottom: 100%;
  min-width: 100%;
  background-color: white;
  color: grey;
  padding: 10px;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all ease;
  z-index: 100;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.info-actions > .menu.active {
  opacity: 1;
}

.info-actions button > i {
  transform: translateY(25%);
}

.info-actions .dropup > i {
  padding-left: 5px;
  border-left: 1px solid white;
}

#edit-working-day {
  display: none;
}

.order-brand-menu {
  position: absolute;
  bottom: 113%;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.order-brand-menu > * {
  padding: 10px;
  background: black;
  color: white;
  border-radius: 6px;
  border: none;
}

.orderDetailsContainer > .tabular-content {
  overflow: visible !important;
}

.edit-order.date-picker-container
  > .react-datepicker__tab-loop
  > .react-datepicker-popper {
  /* transform: translate(550px, 119.5px) !important; */
  transform: translate(0, 0) !important;
  z-index: 1110 !important;
  position: absolute;
  right: auto !important;
  margin: 125px 0px 0px -250px;
  inset: inherit !important;
}

.edit-order.date-picker-container
  > .react-datepicker__tab-loop
  > .react-datepicker-popper
  > div
  > .react-datepicker
  > .react-datepicker__triangle {
  left: -46% !important;
}

.tabular-content {
  margin-top: 20px;
  width: 100%;
}

.tabular-content table {
  width: 100%;
  border-collapse: collapse;
}

.tabular-content table th:first-child {
  width: 30px;
}

.tabular-content table th {
  text-align: left;
  background-color: var(--secendary);
  border-bottom: 1px solid rgb(208, 206, 206);
  padding: 10px;
  overflow: auto;
  text-overflow: ellipsis;
  resize: horizontal;
  border-right: 1px solid grey;
  position: sticky;
  top: 0;
}

.tabular-content table th:first-child,
.tabular-content table th:last-child {
  resize: none;
  border: none;
}

.tabular-content table tr {
  border-bottom: 1px solid rgb(208, 206, 206);
  color: rgb(79, 79, 79);
  max-height: 300px;
}

.tabular-content table tr.row {
  border-bottom: 1px solid rgb(208, 206, 206);
  color: rgb(79, 79, 79);
}

.tabular-content table tr.collapse {
  overflow: scroll;
}

.tabular-content table tr.collapse table {
  width: 70vw;
  height: 100%;
}

.tabular-content table tr.collapse table thead .collapse-nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.collapse table > thead {
  background-color: var(--secendary);
}

.collapse table > thead > tr {
  border: none;
}

.collapse table > tbody > tr > td:first-child {
  text-align: center;
}

.collapse table > thead > tr :nth-child(even) {
  font-weight: 700;
  color: rgb(49, 46, 46);
  text-align: left;
}

.collapse table > thead > tr td {
  font-size: 12px;
}

.collapse-nav-link {
  font-weight: 700;
  font-size: 0.95rem;
  color: rgb(49, 46, 46);
  padding: 0 5px;
}

.collapse-nav-link.active {
  border-bottom: 5px solid green;
}

.collapse-info {
  display: flex;
  justify-content: space-around;
  /* gap: 40px; */
}

.collapse-info > div {
  font-size: 14px;
  /* text-decoration: underline; */
}

.collapse table > tbody > tr > td {
  font-weight: 500;
  font-size: 14px;
}

.collapse table > tbody {
  border: 1px solid black;
  background: #8b8989;
}

.collapse table > tbody > tr:last-child {
  border-bottom: 1px solid black;
}

.collapse table > tbody > .pending > td {
  color: red;
}

.collapse table > tbody > .delivered > td {
  color: green;
}

.tabular-content table tr.collapse > td {
  display: none;
}

.tabular-content table tr.collapse td.active {
  display: table-cell;
}

.tabular-content table td {
  padding: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

.tabular-content table td button {
  padding: 5px;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.tabular-content table tbody {
  padding: 30px;
  background-color: white;
}

.table-details-container {
  display: flex;
  flex-direction: row;
}

#buttons-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: transparent;
  padding: 0 5px;
  margin-left: 10px;
  z-index: 100;
  height: 100%;
  top: 0;
  justify-content: center;
}

#buttons-container > * {
  white-space: nowrap;
  padding: 12px 25px !important;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
}

#buttons-container > .edit {
  background: var(--main);
  box-shadow: 5px 5px 25px 0px rgba(128, 128, 128, 0.616);
}

#buttons-container > .save {
  background: var(--main);
  margin-top: 2px;
}

#buttons-container > .cancel {
  background: red;
}

#details-popup-container {
  display: flex;
  position: absolute;
  z-index: 100;
  right: 15%;
  height: 100%;
  top: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  transition: top 0.4s ease;
  pointer-events: none;
}

#details-popup-container.active {
  top: 0;
  pointer-events: fill;
}

#details-popup-container > div {
  display: flex;
  flex-direction: column;
}

#details-popup-container > div > * {
  padding: 10px 20px;
  background: black;
  color: white;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

td.edit-order-index {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

td.edit-order-index > .cancel-item {
  color: red;
}

td.edit-order-index > .undo-cancel-item {
  color: green;
}

button {
  cursor: pointer;
}

td.edit-order-index > svg {
  margin-right: 15px;
  padding: 2px;
  transition: all 0.3s;
  cursor: pointer;
}

td.edit-order-index > svg:hover {
  padding: 0;
}

.value-edit-inputs.dark {
  background: rgb(37, 37, 37);
  color: white;
  font-size: medium;
  width: auto;
}

.value-edit-inputs {
  white-space: nowrap;
  text-align: center;
  width: 100px;
}

.modal-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
}

.modal {
  width: 400px;
  background-color: white;
  border-radius: 5px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-head {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 10px;
  border-bottom: 1px solid grey;
}

.modal-head > h1 {
  color: var(--main);
  text-align: center;
}

.modal-body {
  padding: 20px;
  flex: 1;
  background-color: white;
  overflow: auto;
}

.modal-content {
  border: 1px solid var(--main);
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

.modal-content > .heading {
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  color: var(--main);
  border-radius: 5px;
  padding: 5px;
}

.modal-content > .row {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
}

.modal-content > .row > .box {
  width: 120px;
  padding: 10px;
  border: 1px solid var(--main);
  border-radius: 5px;
  color: grey;
  display: flex;
}

.modal-content > .row > .box > input {
  width: 20px;
  margin-left: 10px;
  border: none;
  outline: none;
  color: grey;
}

.modal-content > .item {
  display: flex;
  padding: 10px;
  gap: 20px;
}

.modal-content > .item input {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content > .item .name {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9ecef;
  border-radius: 5px;
  border: 1px solid grey;
  color: grey;
}

.modal-content > .item .price {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9ecef;
  border-radius: 5px;
  border: 1px solid grey;
  color: grey;
}

.modal-action {
  padding: 20px;
  padding-top: 0px;
  display: flex;
  justify-content: space-between;
}

.modal-action > button {
  padding: 10px;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all ease;
}

.modal-action > button:first-child {
  background-color: rgba(255, 0, 0, 0.8);
}

.modal-action > button:first-child:hover {
  background-color: rgba(255, 0, 0, 1);
}

.modal-action > button:last-child {
  background-color: rgba(0, 128, 0, 0.8);
}

.modal-action > button:last-child:hover {
  background-color: rgba(0, 128, 0, 1);
}

.popup-item-highlight {
  border: 2px solid black !important;
  border-radius: 10px;
}

.popup-overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.3); */
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
}

.popup {
  width: 400px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border-radius: 50px;
  opacity: 0;
  pointer-events: none;
  padding: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 0%;
  height: fit-content;
  max-height: 80%;
  max-width: 60%;
}

.popup.active,
.popup-overlay.active {
  opacity: 1;
  pointer-events: all;
}

.popup-actions {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 20px;
}

.popup-actions > button {
  padding: 10px;
  border: none;
  outline: none;
  color: white;
  width: 70px;
  border-radius: 10px;
  cursor: pointer;
}

.popup-actions > .cancel {
  background-color: red;
}

.popup-actions > .done {
  background-color: var(--main);
}

.popup > div:not(.popup-actions) {
  overflow: scroll;
  flex: 1;
  padding-right: 10px;
}

.popup .popup-content {
  padding-bottom: 30px;
}

.popup .popup-content > .popup-heading {
  font-size: 20px;
  padding: 10px;
  font-weight: 700;
}

.min-max {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
}

.min-max > div {
  /* width: 120px; */
  padding: 10px;
  border: 1px solid var(--main);
  border-radius: 5px;
  color: grey;
  display: flex;
}

.min-max > div > input {
  width: 20px;
  margin-left: 10px;
  border: none;
  outline: none;
  color: grey;
}

.popup .popup-content > .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
  margin: 0px;
  padding: 0 15px;
}

.popup .popup-content > .item.selected {
  background-color: var(--main);
  border-radius: 10px;
  color: white;
}

.popup .popup-content > .item > div {
  font-size: 14px;
}

.master-menu {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sub-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
}

.sub-header > h1 {
  flex: 1;
  text-align: left;
  font-weight: normal;
  margin: 0;
  padding: 0;
  padding-left: 15px;
  font-size: 1.5em;
}

.sub-header > div {
  display: flex;
  gap: 10px;
  padding-right: 15px;
}

.sub-header > div > button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 7px 0;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: var(--main);
  cursor: pointer;
}

.master-menu-filters {
  display: flex;
  width: calc(100vw - 100px);
  min-height: 150px;
  overflow-x: auto;
}

.master-menu-filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 130px;
  padding: 0 10px;
  margin: 10px;
  border-radius: 15px;
  width: fit-content;
  height: 130px;
  max-height: 130px;
  width: 130px;
  background-color: white;
  text-transform: capitalize;
  box-shadow: 0 5px 17px -7px rgba(0, 0, 0, 0.75);
  transition: all 250ms ease-in-out;
  cursor: pointer;
  border: 2px solid transparent;
}

.master-menu-filter.selected {
  border: 2px solid navy;
}

.master-menu-filter:hover {
  transform: scale(1.02);
}

.master-menu-filter > h4 {
  font-weight: 500;
}

.master-menu-filter > span {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.master-menu-filter > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.master-menu-filter-active {
  border: 1px solid #3533b3;
}

.master-menu-filter > p,
h4 {
  text-align: center;
}

.master-menu-filter.add-new > div {
  background-color: var(--secendary) !important;
  color: black !important;
}

.master-menu-tables {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.master-menu-tables > .table-heading {
  display: flex;
  padding: 20px;
  align-items: center;
  padding: 0px 20px;
}

.mastermenttable {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: auto;
  overflow-y: scroll;
  padding-bottom: 5px;
  height: calc(100vh - 280px);
}

.master-menu-tables > .table-heading > h1 {
  padding: 0 10px;
  font-weight: 600;
}

.master-menu-tables > .table-heading > input {
  width: 20px;
  height: 20px;
}

.master-menu-tables > .table-heading > button {
  padding: 10px;
  border: none;
  outline: none;
  background-color: black;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.master-menu-tables > .content {
  padding: 0px 20px;
}

.multi-select-card > .card {
  border: none;
  outline: 3px solid transparent;
}

.master-menu-tables > div > .drag-overlay {
  box-shadow: 8px 8px 16px grey, -4px -4px 8px grey !important;
  width: 135px;
  height: 135px;
}

.multi-select-card.active > .card {
  outline: 3px solid #31b237;
}

.multi-select-card > .card > .card-actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.multi-select-card > .card > .card-actions > input {
  width: 20px;
  height: 20px;
  background-color: red;
}

.multi-select-card > .card > .card-actions > button {
  padding: 5px;
  background-color: black;
  color: white;
  outline: none;
  border-radius: 5px;
}

.info-action-popup {
  position: absolute;
  bottom: calc(100% + 5px);
  width: 193px;
  border: 1px solid black;
  cursor: default;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all ease;
  background-color: white;
}

.info-action-popup.active {
  opacity: 1;
  pointer-events: all;
}

.info-action-popup > .sub-header > h1 {
  font-size: 18px;
  padding: 5px;
}

.info-action-popup > .info-action-popup-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.info-action-popup > .info-action-popup-content > input {
  padding: 5px;
}

.info-action-popup
  > .info-action-popup-content
  > .info-action-popup-radio-inputs {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.info-action-popup
  > .info-action-popup-content
  > .info-action-popup-radio-inputs
  > .radio-input {
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: space-around;
  align-items: center;
}

.info-action-popup > .info-action-popup-actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.info-action-popup > .info-action-popup-actions > button {
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
}
.info-action-popup > .info-action-popup-actions > .red {
  border: 1px solid red;

  color: red;
  background-color: var(--gray);
}
.info-action-popup > .info-action-popup-actions > button:hover {
  border: 1px solid var(--main);

  color: var(--main);
}
.info-action-popup > .info-action-popup-actions > .red:hover {
  border: 1px solid red;

  color: red;
}

.coupon-input-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000000e3;
  height: 100%;
  width: 100%;
  padding: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.coupon-input-container > input {
  background: #e2e2e2;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 6px 5px;
  width: 90%;
  font-size: 18px;
}

.coupon-input-container > .coupon-btns-container > * {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  margin: 0 5px;
  color: white;
}

.coupon-btns-container > .coupon-save {
  background: var(--mainColor);
}

.coupon-btns-container > .coupon-cancel {
  background: red;
}

.item-edit-popup {
  position: absolute;
  right: -10px;
  width: 250px;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 1))
    drop-shadow(-1px -1px 1px rgba(0, 0, 0, 1));
  /* border: 1px solid black; */
  border-radius: 10px;
  background-color: white;
  z-index: 1;
  display: none;
  transition: 0.3s all ease;
  overflow-x: hidden;
}

.item-edit-popup.active {
  display: block;
}

.item-edit-popup.bottom {
  top: calc(100% + 10px);
}

.item-edit-popup.bottom::after {
  top: 0px;
  transform: rotateZ(212deg) translateY(8px);
}

.item-edit-popup.top {
  bottom: calc(100% + 10px);
}

.item-edit-popup.top::after {
  bottom: 0px;
}

.item-edit-popup::after {
  content: "";
  /* width: 20px;
  height: 20px; */
  border: 10px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  /* border-right-color: transparent;
  border-bottom-color: transparent; */
  /* border: 1px solid black; */
  position: absolute;
  /* top: 0px;  */
  /* bottom: 0px; */
  right: 15px;
  transform: rotateZ(32deg) /* transform: rotateZ(212deg) */ translateY(8px);
  /* translateY(8px); */
  /* background-color: white; */
}

.item-edit-popup > textarea {
  width: 100%;
  resize: none;
  padding: 5px;
  padding-top: 10px;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 10px 10px 0px 0px;
  outline: none;
}

.item-edit-popup > .item-edit-popup-actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  padding-bottom: 10px;
  gap: 5px;
}

.item-edit-popup > .item-edit-popup-actions > button {
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
  color: #000;
  background: var(--gray);
}
.item-edit-popup > .item-edit-popup-actions > button:hover {
  border: 1px solid var(--main);

  color: var(--main);
}
.item-edit-popup > .item-edit-popup-actions > .red:hover {
  border: 1px solid red;

  color: red;
}

.master-menu-tables-options {
  width: 30px !important;
  height: 30px !important;
  background-color: red !important;
  border-radius: 50% !important;
  position: relative !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.master-menu-tables-options > .master-menu-tables-options-dropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: 0px;
  background-color: black;
  width: max-content;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 10;
}

.master-menu-tables-options > .master-menu-tables-options-dropdown > div {
  padding: 10px;
  color: white;
}

.master-menu-tables-options > .master-menu-tables-options-dropdown > div:hover {
  background-color: var(--main);
}

tr:nth-child(1) {
  width: 40%;
}

tr:nth-child(2),
tr:nth-child(3),
tr:nth-child(4) {
  width: 20% !important;
}

.notification-container {
  z-index: 10000;
  background: rgba(0, 0, 0, 0.849);
  position: fixed;
  bottom: -150px;
  left: 50%;
  padding: 25px 20px;
  font-size: 20px;
  max-width: 80%;
  width: fit-content;
  color: white;
  font-size: larger;
  transform: translate(-50%, 0px);
  transition: all 0.2s ease-in;
  border-radius: 8px;
}

.notification-container.permission {
  background: orange;
  display: flex;
  align-items: center;
  gap: 10vw;
}

.notification-container.permission.active {
  bottom: 50px;
}

.notification-container.permission button {
  padding: 0.8rem 1rem;
  border-radius: 10px;
  background: #fff;
  border: none;
  font-weight: 600;
}

.notification-container.active-red {
  background: rgba(255, 0, 0, 0.904);
  bottom: 50px;
}

.notification-container.active-green {
  background: var(--main);
  bottom: 50px;
}

.notification-container.active-orange {
  border-radius: 1rem;
  background: orange;
  bottom: 50px;
}

#talkbubble {
  color: white;
  background: black;
  position: relative;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  right: 92px;
  padding: 2px;
  width: fit-content;
  z-index: 1;
}

#talkbubble > * > td {
  white-space: nowrap;
}

#talkbubble:before {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 60%;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-bottom: 12px solid black;
  border-right: 6px solid transparent;
}

.secondary_screen {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #e9ecef;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    140deg,
    var(--gray) 0%,
    #fff 30%,
    var(--main) 75%
  );
}

.brandAdmin {
  width: 50vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brandAdmin img {
  width: 70%;
  border-radius: 50%;
  box-shadow: 10px 20px 50px var(--black);
}

.refresh_button {
  height: 40px;
  cursor: pointer;
  background: var(--main);
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 500;
  transition: all 0.3s ease;
  margin-left: 10px;
}

.refresh_button:hover {
  background: black;
}

.copies_notification {
  cursor: pointer;
  position: fixed;
  bottom: -150px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background: var(--main);
  color: #fff;
  padding: 15px 40px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  width: 300px;
  transition: all 0.5s ease;
}

.copies_notification_active {
  transition: all 0.5s ease;
  bottom: 50px;
}

.login_date_picker .react-datepicker-popper {
  position: relative !important;
  transform: inherit !important;
}

.newCategory .react-datepicker-popper {
  z-index: 100000 !important;
}

.secondary_screen .react-datepicker-wrapper,
.secondary_screen .react-datepicker__input-container,
.secondary_screen .react-datepicker__input-container input {
  display: block;
  text-align: center;
  width: 100%;
}

.itemavilabelitycontainer .react-datepicker-wrapper,
.itemavilabelitycontainer .react-datepicker__input-container,
.itemavilabelitycontainer .react-datepicker__input-container input {
  display: block;
  text-align: center;
  width: 100%;
}

.select-option {
  width: 200px;
}

.select-options {
  width: 200px;
  height: 35;
}

.customer_balance_payment:hover {
  color: var(--main);
  border: 1px solid var(--main);
}

.itemavilabelitycontainer .react-datepicker-popper {
  z-index: 10000 !important;
}

.customer_balance_payment_button {
  border: none;
  width: 80%;
  margin: 5px 0;
  background: var(--main);
  color: #fff;
  padding: 5px 0;
  border-radius: 10px;
}

.customer_balance_payment_button:hover {
  background: var(--main);
}

.rbc-off-range {
  color: rgba(114, 101, 101, 0.5) !important;
}

.rbc-off-range-bg {
  background-color: transparent !important;
}

.rbc-button-link {
  color: #000 !important;
}

.rbc-off-range-bg .rbc-off-range .rbc-button-link {
  color: rgba(243, 1, 1, 0.521) !important;
}

.rbc-day-bg {
  color: rgba(243, 1, 1, 0.521) !important;
}

.rbc-row-segment {
  position: relative !important;
}

div.scheme_select__control {
  border-radius: 8px;
  width: 20rem;
}

div.scheme_select_small_container > div.scheme_select__control {
  width: 12rem;
}

div.scheme_select_sided_container > div.scheme_select__control {
  width: 6rem;
  border-radius: 0 8px 8px 0;
}

div.scheme_select__control:hover {
  border: 1px solid #cccccc;
}

div.scheme_select__control--is-focused {
  border: 1px solid #cccccc;
  box-shadow: none;
}

div.scheme_select__menu-list > *:hover {
  background: rgba(128, 128, 128, 0.067);
}

div.scheme_select__option--is-selected {
  background: var(--main);
}

div.scheme_select__option--is-selected:hover {
  background: var(--main);
}

.fieldEditButton {
  border: none;
  text-decoration: none;
  padding: 6px 12px;
  margin: auto 10px;
  background: var(--main);
  border-radius: 5px;
  color: #f2f2f2;
  cursor: pointer;
  font-size: 12px !important;
}

.user-table tbody .fieldEditButton:hover {
  background: #44cd4b85;
}

.overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  background-color: var(--white);
  border-radius: 1rem;
  position: relative;
  height: fit-content;
  padding: 20px;
}

.modal .content {
  /* overflow: scroll; */
  padding: 0;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 12px;
  padding: 0 0.5rem;
  background-color: black;
  color: white;
  border: 0;
  font-size: 2rem;
  border-radius: 100vh;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.closeButton:hover {
  background-color: var(--mainColor);
}

.noBgActionButton {
  border: none !important;
  color: white !important;
  padding: 0.4rem 2rem !important;
  border-radius: 0.5rem;
}

#popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: #000000b3;
  z-index: 100;
}

.multi-device-warning-container {
  background: white;
  padding: 50px 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 15px;
}

.multi-device-warning-container > p {
  font-size: 24px;
  white-space: nowrap;
  color: #3c3c3c;
}

.multi-device-warning-container .buttons {
  display: flex;
  gap: 10px;
}

.multi-device-warning-container button {
  border-radius: 5px;
  border: none;
  outline: none;
  color: white;
  font-size: 20px;
  width: 120px;
  height: 60px;
}

.multi-device-warning-container #confirm {
  background: var(--main);
}

.multi-device-warning-container #cancel {
  background: red;
}

.order-detail .kot-item.cancelled {
  color: red;
}

.order-detail .kot-item.prepared {
  color: green;
}

.order-detail .kot-item.unprepared {
  color: black;
}

#fileoutput-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px 4px grey;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1.5rem;
  border-radius: 1rem;
  flex-flow: column nowrap;
  background: white;
}

#fileoutput-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#fileoutput-options div {
  display: flex;
  gap: 10px;
}

#fileoutput-container button {
  border: none;
  background: var(--main);
  color: white;
  padding: 0.25rem 0;
  width: 150px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
}

#fileoutput-container button svg {
  color: white;
}

#upi_settings input[type="text"] {
  background: #efefef;
  border: 1px solid darkgrey;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  margin: 15px 0 0;
  line-height: normal;
}

#upi_settings input[type="text"].invalid {
  background: #ff000014;
  outline: 1.5px solid red;
  border: none;
}

.customer-group-name-wrapper,
.customer-group-name-wrapper > div {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
}

.customer-group-name-wrapper {
  width: 100%;
}

.customer-group-name-wrapper p {
  width: 100%;
  text-align: left;
}

.customer-group-name-wrapper button {
  font-size: 10px;
}

.customer-group-name-wrapper button,
svg {
  cursor: pointer;
}

#notification_dropDown {
  position: relative;
  background-color: var(--black);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 5px;
  right: 92px;
  padding: 2px;
  width: fit-content;
  z-index: 1;
}

#notification-count {
  right: -1%;
  position: absolute;
  top: 2px;
  background: red;
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  border-radius: 100%;
  padding: 1px 6px;
  aspect-ratio: 1;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.header_toggle_btn .toggle_dropdown.notifications-panel {
  padding: 0px;
  border-radius: 5px;
  overflow: hidden;
  border: none;
  max-width: 450px;
  min-width: 380px;
}

.notifications-panel #wrapper-header {
  background-color: var(--main);
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 16px;
  font-weight: bolder;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifications-panel #notifications-wrapper {
  color: rgb(0, 0, 0);
  width: 100%;
  max-height: 600px;
  overflow: hidden auto;
}

.notification-icon-wrapper {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  aspect-ratio: 1;
}

.notification-icon-wrapper img {
  object-fit: cover;
  min-width: 100%;
  border-radius: 100%;
}

.notification-icon-wrapper svg {
  height: fit-content;
}

.notification-icon-wrapper svg.payment {
  padding-top: 5px;
  width: 85%;
}

.notification-text-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.notification-text-wrapper.full-width {
  width: 100%;
}

.notification-text-wrapper > div:first-child {
  font-size: 16px;
  padding: 10px 0px;
  width: 100%;
}

.notification-text-wrapper > div:first-child > span:first-child {
  font-weight: bolder;
  display: block;
}

.notification-text-wrapper > div:first-child > span:last-child {
  display: block;
}

.notification-text-wrapper > div:last-child {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notification-text-wrapper > div:last-child > span {
  display: block;
  font-weight: 500;
}

.notification_item {
  color: var(--black);
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-bottom: 0.5px solid var(--black);
  cursor: pointer;
  justify-content: space-between;
}

.notification_item > div:first-child {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

#notification-setup-field-icons-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

#notification-setup-field-icons-wrapper div {
  cursor: pointer;
}

span.notifcatipon-print-button {
  text-decoration: underline;
}

.capital {
  text-transform: capitalize;
}

#videos-wrapper {
  padding: 20px 40px 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

#videos-page #search_bar {
  outline: none;
  background: #eeeeee;
  padding: 10px 20px;
  border-radius: 5rem;
  width: 80%;
  font-weight: 500;
  border: 2px solid #cfcfcf;
  line-height: normal;
  color: #7e7e7e;
}

.video-raw {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 5px -2px black;
  display: flex;
  display: flex;
  flex-direction: column;
  width: calc(33% - 10px);
}

iframe.help-youtube-video {
  aspect-ratio: 1.775;
  border: none;
}

.video-raw iframe {
  border: none;
  pointer-events: none;
}

.video-raw .data {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.video-raw .data h2,
.video-raw .data p {
  overflow: hidden;
  display: block;
  -webkit-line-clamp: 2;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.video-raw .data h2 {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  max-height: 4rem;
  margin-bottom: 10px;
}

.video-raw .data p {
  font-size: 1rem;
  font-weight: 500;
  max-height: 4rem;
  color: #656565;
  line-height: normal;
}

#videos-page #table-container {
  height: calc(100vh - 159px);
  overflow: auto;
}

#videos-page #filters-search-btn {
  margin: 0;
  padding: 23px 1.7em;
}

#videos-page .filters-form .inputs-container {
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 40px 10px;
  gap: 20px;
  width: calc(100vw - 100px);
}
.animation-buttons:hover button {
  color: var(--main);
}
.nav_link_container:hover svg,
.nav_link_container:hover p {
  color: var(--main);
}
/* @keyframes seat {
  from {
    opacity: 0.3;
  }
  to {
    outline: 1;
  }
} */

.video-language-select {
  padding: 10px;
  text-align: center;
  background: #e6e6e6;
  margin-bottom: 5px;
  border-radius: 8px;
}

.video-language-select.selected {
  background: var(--main);
  color: #fff;
}

#bottom-help-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  gap: 2px;
  background: #ff0000;
  color: white;
  cursor: pointer;
}

#bottom-help-btn svg {
  width: 1.15em;
  height: 1.15em;
}

#upi_settings .input-div label {
  text-align: left;
  align-items: flex-start;
}

#youtube-video-player {
  width: fit-content;
  background: white;
  padding-bottom: 15px;
  border-radius: 1rem;
  overflow: hidden;
}

#youtube-video-player iframe {
  min-height: 85vh;
  max-width: 90vw;
}

#youtube-video-player span {
  padding: 10px 20px 0;
  display: block;
  font-size: 14px;
}

#youtube-video-player-wrapper {
  position: relative;
}

#youtube-video-player-wrapper svg#close {
  position: absolute;
  top: 0;
  right: 0;
  translate: calc(100% + 1rem) 50%;
  background: #141414;
  color: #ff0000;
  border-radius: 100%;
  padding: 5px;
  font-size: 1.65rem;
  cursor: pointer;
}

.left-panel-items {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.left-panel-items svg {
  width: 1em;
  height: 1em;
}

.left-panel-items .icons-wrapper {
  display: flex;
  gap: 5px;
}

.left-panel-items .icons-wrapper button {
  background: transparent;
  border: none;
}
.pricing-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 1rem;
  align-items: center;
}
.page-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: calc(100% - 35px);
  width: 100%;
}
.header-new {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0.1rem;
  flex: 1;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}
.heading-new {
  color: #000000;
  font-family: "Quicksand", sans-serif;
  font-size: 70px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  width: 100%;
  text-align: center;
  background: linear-gradient(0deg, var(--green) 8px, transparent 7px) 0 1.2em;
}
