body {
  margin: 0;
}

#suspense-fallback-ui {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--main);
  font-size: 4vmin;
  gap: 7.5vmin;
}

#suspense-fallback-ui img#suspense-logo {
  width: 40vmin;
  height: 40vmin;
  /* box-shadow: 0 0 8vmin 0vmin #000000; */
  /* border-radius: 100%; */
  /* border: 1px solid; */
  margin-top: 7.5vmin;
  /* background: black; */
}

#suspense-fallback-ui img[alt="spinner"] {
  width: 8vmin;
  height: 8vmin;
  margin: 0 !important;
}

:root {
  --main: rgb(68, 205, 74);
  --gray:#f3f5f7;
  --secendary:#46A6EA;
  --white:#ffffff;
  --black:#000000;
}
.form {
  display: flex;
  flex-direction: column;
  /* gap: 1.2rem; */
  justify-content: space-between;
  height: 100%;
  height: fit-content;
  overflow: hidden;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  overflow: auto;
}

.generalTop {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  font-size: 20px;
  margin: 10px 0 20px;
}

#top_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 15px;
  align-items: flex-end;
}

#top_content>div>label {
  min-width: 8rem;
  height: 8rem;
  background: #80808029;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}

#top_content>div>label>span {
  display: block;
  width: 8rem;
  height: 100%;
  text-align: center;
  padding: 45px 0;
  color: grey;
  font-size: 17px;
  font-weight: 600;
}

.brand_invoice_logo {
  object-fit: cover;
  width: auto;
  max-width: 35rem;
}

#delete_logo {
  margin: auto;
  margin-top: -34px;
  width: 34px;
  height: 34px;
  padding: 6px;
  background: #e91000e8;
  color: white;
  border-radius: 100%;
  transition: all .3s;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#delete_logo:hover {
  padding: 3px;
}

.noSpaceForm {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  height: 100%;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: var(--main) solid 2px;
  border-radius: 1rem;
  padding: 1.2rem;
  position: relative;
}

.formGroup>h2 {
  font-weight: 600;
}

.kotGeneral {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  margin: 10px;
}

#colorsMethods {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  justify-content: space-around;
}

.kotGeneral>#colorsMethods>div {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
}

.colorLabels>div {
  display: inline-block;
  width: 70px;
}

.colorLabels>input {
  padding: 8px;
  border: none;
  background: #00000015;
  border-radius: 5px;
  margin: 10px;
  width: 70px;
  text-align: center;
}

.selectLabel {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.selectLabel>input,
.selectLabel>select,
.selectLabel>textarea {
  border-radius: 1rem;
  padding: 5px 10px;
  outline: none;
  border: 0.5px solid grey;
  letter-spacing: 0.5px;

}

.checkboxLabel {
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  align-items: center;
}

.select,
.multiSelect,
.numberInput {
  padding: 0.2rem;
  font-size: medium;
}

.select,
.numberInput {
  height: 2.4rem;
}

.select,
.multiSelect,
.numberInput,
.messageTextarea {
  outline-color: var(--mainColor);
}

.messageTextarea {
  height: 5rem;
  font-family: sans-serif;
  resize: vertical;
}

.multiSelect,
.messageTextarea,
.emptyCol {
  width: 16rem;
}

.select {
  width: 12rem;
}

.row {
  display: flex;
  gap: 1rem;
  position: relative;
  align-items: flex-end;
}

.row>h1 {
  text-align: center;
  width: 100%;
  font-weight: 600;
}

.submit {
  padding: 0.5rem 1.5rem;
  font-size: large;
  background-color: var(--main);
  color: white;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 1px;
  height: 40px;
}

.multiSelect {
  height: 5rem;
}

@media (max-width: 1024px) {
  .row {
    flex-direction: column;
  }
}

.addMealButton {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--mainColor);
  border: none;
  border-radius: 0.2rem;
}

.addMealButton>img {
  width: 70%;
  height: 70%;
}

.groupLabel {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.fieldCustomizationButton {
  padding: 15px 20px;
  font-size: large;
  background-color: #00b0f0;
  color: white;
  border: none;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 2rem;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
}

.fieldCustomizationButton:disabled {
  cursor: not-allowed;
  background: #9fa8af;
}

.bottom_cover {
  margin-bottom: 18px;
}

.itemGroupingButton {
  padding: 0.2rem;
  font-size: medium;
  background-color: #00b0f0;
  color: white;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  padding: 0 1rem;
  height: 2.2rem;
  margin-left: 2rem;
}

.blueButtonGroup {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 1rem;
}

.selections {
  border: 1px solid black;
  padding: 2px;
  width: 16rem;
}

.selections.layout {
  padding: 10px;
  border-radius: 10px;
  background: #f1f1f2eb;
  border: none;
  box-shadow: 0 0 3px 0px #c0c0d1;
}

.option {
  cursor: pointer;
  vertical-align: center;
  padding: 1px;
}

.option:hover {
  color: white;
  background: var(--main);
}

.selected {
  color: white;
  padding: 1px;
  cursor: pointer;
  background: #14bd1c;
}

.deleteBtn {
  background: none;
  width: fit-content;
  height: fit-content;
  padding: 2px;
  color: white;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border: none;
  border-radius: 0.2rem;
  cursor: pointer;
}

.deleteBtn:hover {
  background-color: black;
  color: red;
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  margin-right: 0.5rem;
  height: 28px;
  display: flex;
  flex-direction: column;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  border-radius: 34px;
}

.slider:before {
  border-radius: 50%;
}

.heading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: white;
  font-size: medium;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  position: sticky;
}

.heading>h1 {
  margin: 10px 0;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0 4rem;
}

.status {
  display: flex;
  align-items: center;
  gap: 10px;
}

div.kotColorCircles {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: orange;
  margin-right: 10px;
  border: 1px solid #bfbfbf;
  margin-bottom: -5px;
}

span.loadingDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0000006b;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.loadingDiv img {
  width: 100px;
  height: 100px;
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: 1rem;
  overflow-y: scroll;
}

.searchInput {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: medium;
  max-width: 32rem;
}

#setupMenuToggler {
  border: none;
  outline: none;
  border-radius: 8px;
  margin: auto 10px;
  background: #fff;
  box-shadow: 0 0 5px 0 grey;
  transition: all .5s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
}

#setupMenuToggler svg {
  margin-right: -4px;
  margin-left: 8px;
  transform: rotate(359deg);
  transition: all .5s ease;
}

#setupMenuToggler.active svg {
  transform: rotate(180deg);
}

#setupMenuWrapper {
  position: relative;
  border: none;
  outline: none;
  background: none;
}

#setupMenu {
  position: absolute;
  background: #f6f6f6;
  padding: 5px 0;
  white-space: nowrap;
  right: 0;
  top: 115%;
  border-radius: 7px;
  box-shadow: 0 0 5px 0 grey;
}

#setupMenu>* {
  text-align: left;
  padding: 10px;
  color: black;
  display: flex;
  align-items: center;
}

#setupMenu>*:hover {
  background: var(--main);
  color: white;
}

.subMenuToggler {
  padding-right: 15px;
  position: relative;
}

.subMenuToggler>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subMenuToggler svg {
  transition: transform .5s ease;
}

#orderingLinksList {
  position: absolute;
  top: 105%;
  right: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 1px 0px;
}

#orderingLinksList>* {
  display: flex;
  align-items: center;
  justify-content: left;
  background: white;
  color: black;
  padding: 10px 20px;
  border-bottom: 1px solid #ebebeb;
  background: #f6f6f6;
}

#orderingLinksList>*:hover {
  color: white;
  background: var(--main);
}
#login-container {
  width: 100%;
  height: 100%;
  background: #44cd4a;
}

#login-container h1 {
  color: #fff;
  font-size: 60px;
  margin: 0;
  letter-spacing: 3px;
}

#login-container > .form {
  width: 95%;
  height: 100vh;
  padding-left: 50px;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
  position: absolute;
  top: 0px;
  right: 0px;
  background: #000;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  width: 90%;
  gap: 8px;
  background: transparent;
}

#login-container .input-container input {
  background: transparent;
  border: none;
  border-bottom: 2px solid #fff;
  color: #fff;
  font-size: 20px;
}

#login-container .input-container input:-internal-autofill-selected {
  background: black;
  border: none;
  border-bottom: 2px solid #fff;
  color: #fff;
  font-size: 20px;
}

.input-container > label {
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 1.5px;
  font-weight: 600;
}

.submit-btn {
  padding: 10px 3rem;
  background: #44cd4a;
  border: 2px solid #44cd4a;
  border-radius: 2rem;
  width: fit-content !important;
  min-width: 10rem;
  font-weight: bold;
  font-size: 23px;
  color: white;
  letter-spacing: 1.5px;
  cursor: pointer;
}
