.orders-report-container {
    position: absolute;
    top: 40px;
    left: 60px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    overflow: hidden;
}

.orders-report-container>#heading {
    width: calc(100vw - 70px);
    background-color: black;
    color: white;
    margin: 0;
    text-align: center;
}

.orders-report-container>#heading>h2 {
    margin-right: 212px;
    padding: 7px;
}

#filters-container {
    background: white;
}

#searchbar-container {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    gap: 10px;
}

#searchbar-container>input {
    padding: 8px;
    /* border-radius: 3px; */
    width: 100%;
    font-size: 16px;
    transition: all .2s ease-in;
}

#searchbar-container>input:hover {
    background-color: #b6b6b63b;
}

#searchbar-container>button {
    border: none;
    outline: none;
    padding: 10px 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    background-color: black;
    font-size: 16px;
    font-weight: 500;
}

#searchbar-container>button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    font-weight: 400;
}

.inputs-container {
    display: flex;
    padding: 10px 0;
}

.selection-field {
    margin: 10px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.selection-field>label {
    margin-bottom: 5px;
}

/* DATE PICKER STYLING */
.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: black;
}

.react-datepicker__day.react-datepicker__day--today {
    font-weight: 800;
}


.date-display {
    position: absolute;
    margin-top: 22px;
    background: #a6a6ff;
    font-weight: 600;
    padding: 5px;
    margin-left: 5px;
}

.date-field {
    width: min-content;
}

.date-picker.small {
    width: 8rem;
}

.date-picker.test {
    width: min-content;
    text-align: start;
}

.date-field.from.orders-page>.react-datepicker__tab-loop>.react-datepicker-popper {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    transform: translate(82.5px, 115.5px) !important;
}

.date-field.to.orders-page>.react-datepicker__tab-loop>.react-datepicker-popper {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    transform: translate(316.5px, 115.5px) !important;
}

.field-title {
    margin: .5rem 0;
}

.dropdown-trigger {
    min-width: 175px;
    transition: all .2s ease;
}

.dropdown-trigger:hover+.show-selections {
    display: flex;
}

.report-transparent-btn {
    border: none;
    background: var(--main);
    padding: 0.7rem 1.2rem;
    margin-top: auto;
    border-radius: 0.3rem;
    color: #00c400;
    font-weight: 600;
    font-size: .9rem;
}

.dropdown-trigger,
#filters-popup-trigger,
#filters-search-btn {
    height: 40px;
    background: none;
    border: 1.5px solid black;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1px;
}

.dropdown-trigger>.arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    padding: 2px;
    transform: rotate(45deg);
    z-index: -1;
}

.show-selections {
    position: absolute;
    margin-top: -10px;
    min-width: 175px;
    width: auto;
    background: #eeeeee;
    display: none;
    padding: 13px;
    z-index: 100;
    box-shadow: 1px -5px 5px rgba(128, 128, 128, 0.445);
    font-weight: 500px;
}

.show-selections>*,
#trigger-text>* {
    padding-right: 3px;
    white-space: nowrap;
}

.show-selections>*::after {
    content: ",";
}

#trigger-text>:nth-child(2)::before {
    content: ', ';
}

#trigger-text>:nth-child(2)::after {
    content: '...';
}

.dropdown {
    position: absolute;
    margin-top: 73px;
    min-width: 175px;
    background-color: white;
    font-size: 14px;
    border-radius: 2px;
    overflow: hidden;
    border: none;
    transition: all .3s ease;
    display: none;
    z-index: 10;
}

.dropdown.edit-columns {
    margin-top: 40px;
}

.dropdown.visible {
    border: 1px solid #00000054;
    display: block;
    max-height: 290px;
    overflow: auto;
}

.dropdown .input-div {
    padding: 5px;
    color: #000000c2;
    transition: background .3s ease;
}

.dropdown .input-div:hover {
    background: #0000001e;
}

.dropdown-trigger:hover {
    background: #6e6e6e23;
}

.input-div label {
    display: flex;
    align-items: center;
}

.input-div input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.input-div[lable="Local Order No"]>label {
    color: grey;
}

.input-div[lable="Local Order No"]>label>input:checked~.checkmark {
    background: rgba(128, 128, 128, 0.383);
}

.checkmark {
    height: 25px;
    width: 25px;
    background-color: #eee;
    margin: 0 5px;
    border-radius: 2px;
}

.input-div:hover input~.checkmark {
    background-color: #ccc;
}

.input-div input:checked~.checkmark {
    background-color: var(--main);
}

.input-div .checkmark:after {
    content: "";
    position: relative;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.input-div input:checked~.checkmark:after {
    display: block;
}

.date-picker {
    width: 170px;
    padding: 7px;
    font-family: Arial, Helvetica, sans-serif;
    border: 2px solid;
}

#filters-popup-trigger {
    outline: none;
    height: fit-content;
    width: fit-content;
    border-color: transparent;
    color: white;
    margin: auto 10px 10px 10px;
    padding: 10px 15px;
    border-radius: 4px;
    background: var(--black);
    transition: all .4s ease;
}

/* From cssbuttons.io by @nikk7007 */
#filters-search-btn {
    --color: var(--main);
    min-width: fit-content;
    padding: 0.8em 1.7em;
    background-color: black;
    border-radius: .3em;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .5s;
    font-weight: 600;
    font-size: 17px;
    border: none;
    font-family: inherit;
    color: white;
    margin: auto 10px 10px 10px;
    z-index: 1;
}

#filters-search-btn::before,
#filters-search-btn::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--color);
    transition: .7s ease;
}

#filters-search-btn::before {
    top: -1em;
    left: -1em;
}

#filters-search-btn::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

#filters-search-btn:hover::before,
#filters-search-btn:hover::after {
    height: 200px;
    width: 200px;
}

#filters-search-btn:hover {
    color: white;
}

#filters-search-btn:active {
    filter: brightness(.8);
}


#filters-popup-trigger {
    padding: 5px;
    background: none;
}

#filters-popup-trigger:hover {
    background: #0000001f;
}

#filters-popup-trigger>svg {
    width: 40px;
    color: black
}

#rightSided {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}


#pay-modes-table {
    position: absolute;
    top: 120px;
    right: 190px;
    border-radius: 8px;
    border: 1px solid rgba(128, 128, 128, 0.561);
    overflow: hidden;
    background-color: var(--main);
    z-index: 1000000;
    outline: none;
}

.pay-modes-body {
    max-height: 500px;
    display: block;
    overflow-y: scroll;
    margin-right: -13px;
    overflow-x: hidden;
    margin: -2px -14px -4px -3px;
    background-color: white;
}

.pay-modes-head>tr>th {
    width: 50%;
    color: white;
}

.pay-modes-body>tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid grey;
    width: 100% !important;
}

.pay-modes-body>tr:hover {
    background-color: rgba(177, 177, 177, 0.226);
}

.pay-modes-body>tr>td,
.pay-modes-head>tr>th {
    white-space: nowrap;
    min-width: 80px;
    text-align: start;
    padding: 10px;
    display: inline-block;
}

.pay-mode-toggle {
    transition: all .5s ease;
    padding: 11px !important;
    cursor: pointer;
}

.pay-mode-toggle:hover,
.pay-mode-toggle.active {
    border-radius: 3px;
    background: #44cd4b2e;
    color: #2fa234;
}

.report-stats {
    white-space: nowrap;
    padding: 10px;
    margin: auto 0 10px;
}

.report-stats.container {
    margin-bottom: 0;
}

.report-stats.container>* {
    display: block;
    margin-bottom: 5px;
    min-width: 180px;
}

#edit-column {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
}

#edit-column>.selection-field {
    width: 175px;
}

#edit-column>.selection-field>.dropdown-trigger {
    border: none;
    transition: background .6s ease;
    justify-content: flex-start;
}

#edit-columns-pencil {
    height: 20px;
    margin: 0 5px;
    color: black;
}

#edit-column>.selection-field>.dropdown-trigger:hover {
    border-radius: 3px;
    background: #44cd4b2e;
    color: #2fa234;
}

#edit-column>.selection-field>.dropdown-trigger:hover #edit-columns-pencil {
    color: #2fa234;
}

.report-orders-table {
    width: 100%;
    height: 100%;
    overflow: auto;
    margin-right: 0;
    margin-top: 0;
}

.report-fields {
    cursor: pointer;
}

.report-fields:hover {
    background-color: rgba(128, 128, 128, 0.164);
}

.report-fields>td {
    text-align: center;
    white-space: nowrap;
}

td.order_total::before,
td.selected_mode_amount::before {
    content: 'Rs.';
}

div#no_reports {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 22px;
}

#sidebar-container {
    width: 0;
    height: calc(100vh - 177.07px);
    position: absolute;
    transition: background .5s ease;
    background: none;
    z-index: 1;
}

#filters-sidebar {
    height: calc(100vh - 177.07px);
    position: absolute;
    background: white;
    top: 0;
    left: -500px;
    padding: 15px 25px;
    border-top: 1px solid #8080808c;
    transition: left .2s ease-in;
    z-index: 1;
}

#sidebar-container.active {
    background: #00000085;
    width: 100%;
}

#filters-sidebar.active {
    left: 0px;
    overflow-y: auto;
}

::-webkit-resizer {
    visibility: hidden;
}

#reports-loading-spinner {
    width: 100%;
    height: calc(100% - 140px);
    position: absolute;
    top: 135px;
    left: 0;
    margin: auto;
    z-index: 1000;
    background: rgb(255, 255, 255);
}

#reports-loading-spinner>img {
    display: block;
    position: absolute;
    top: calc(50% - 75px);
    left: 50%;
    transform: translate(-50%, -50%);
}

#table-container {
    width: calc(100vw - 85px);
    height: calc(100vh - 177.4px);
    overflow-x: scroll;
    padding-bottom: 45px;
}

.t-head-element {
    display: flex;
}

.t-head-element span {
    margin: auto 10px;
    white-space: nowrap;
}

.tabular-content table th>*::after {
    content: '>>';
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: cursive;
    font-size: 10px;
    font-weight: 600;
    border-radius: 10px;
    transition: background .4s ease;
    padding: 3px;
}

.sort-buttons-container {
    display: flex;
    flex-direction: column;
}

.sort-buttons-container button {
    background: none;
    outline: none;
    border: none;
    height: 15px;
}

.sort-buttons-container button:hover .sort-button {
    color: var(--white);
    background: rgba(134, 134, 134, 0.705);
}

.sort-button {
    color: black;
    height: 15px;
    cursor: pointer;
    transition: background .4s ease;
    border-radius: 10px;
}

.sidebar-row>* {
    margin: 5px;
}

.sidebar-row {
    display: flex;
    justify-content: space-between;
}

.single-element>.selection-field>.dropdown {
    width: 360px;
}

#loadingDiv {
    padding: 10px;
    text-align: center;
}

#loadingDiv>img {
    height: 35px;
}

.pages-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 20px;
}

.pages-container>* {
    border: 1px solid grey;
    border-radius: 1rem;
    padding-left: 10px;
    padding-top: 5px;
    margin: 0 5px;
    transition: all .5s ease;
    width: 30px;
    height: 30px;
}

.pages-container>*:hover {
    background: rgba(187, 187, 187, 0.349);
}


/* CUSTOMER REPORTS STYLING */
.customer-reports-container {
    background: white;
    height: -webkit-fill-available;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
    width: calc(100vw - 75px);
}

.customer-reports-container.table-container-user {
    left: 0 !important;
}

.item-sales-container {
    padding: 0px !important;
    background: white;
    width: calc(100vw - 70px);
    left: 0;
    height: 100%;
}

#item-sales-top {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    gap: 20px;
}

#date-input-container {
    display: flex;
    gap: 10px;
}

div#date-input-container>label {
    max-width: min-content;
}

input#item-sales-searchbar {
    padding: 8px;
    margin-top: 14px;
    border-radius: 4px;
    width: 350px;
    border: 1px solid;
}

.item-sales-search {
    border: none;
    padding: 10px 20px;
    font-weight: bold;
    color: white;
    background-color: black;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 5px;
    height: fit-content;
    margin-top: 14px;
    gap: 5px;
}

.pagination {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    display: flex;
    justify-content: center;
}

.pagination.orders-report {
    bottom: 9px;
}

.pagination div {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
}

.pagination div.active {
    background-color: #4CAF50;
    color: white;
}

.pagination div:hover:not(.active) {
    background-color: #ddd;
}